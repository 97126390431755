import React from "react"
import { Link } from "gatsby"

export default class Team extends React.Component {
  render() {
    return (
      <div className="container mx-auto mt-8 sm:mt-10 xl:mt-12">
        <h3 className="mb-6 tracking-wider text-center text-xl lg:text-2xl 2xl:text-3xl">
          Core Team
        </h3>
        <div className="grid gap-8 mt-8 md:grid-cols-2">
          <div className=" flex flex-col tracking-wider items-center p-4 rounded shadow-lg ">
            <h2 className="mt-3 text-lg xl:text-xl">Shubham Gautam</h2>

            <h3 className="text-xs p-1 sm:text-sm 2xl:text-base text-gray-600 leading-6 text-center">
              Founder | Author | Mentor
            </h3>
            <Link
              to="/shubham-gautam/"
              className="px-3 mt-2 w-auto text-sm sm:text-base 2xl:text-lg tracking-wider shadow bg-new-green text-white hover:bg-light-green hover:text-gray-900 py-1 rounded transition duration-150"
            >
              Shubham Gautam Blogs
            </Link>

            <h3 className="text-xs mt-3 p-1 sm:text-sm 2xl:text-base text-gray-600 leading-6 text-center">
              DSA | System Design | OOPS | Math
            </h3>

            <div className="flex">
              <a
                href="https://www.linkedin.com/in/shubhamgautamsuper30/"
                className="mt-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="sr-only">Linkedin</span>
                <svg
                  x="0px"
                  y="0px"
                  width="50"
                  height="50"
                  viewBox="0 0 48 48"
                  className="w-8 h-8"
                >
                  <path
                    fill="#0288D1"
                    d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                  ></path>
                  <path
                    fill="#FFF"
                    d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                  ></path>
                </svg>
              </a>
              <a
                href="https://shubhamgautamsuper30.medium.com/"
                className="mt-4 ml-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="sr-only">Medium</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  width="26px"
                  height="26px"
                >
                  <path d="M 3 6 L 7 11.091797 L 7 35.285156 L 1 43 L 15 43 L 9 35.285156 L 9 13.75 L 22 43 L 21.998047 43.013672 L 34 13.544922 L 34 39 L 30 43 L 47 43 L 43 39 L 42.972656 10.503906 L 46.863281 6.0136719 L 34.845703 6.0136719 L 25.605469 28.744141 L 15.496094 6 L 3 6 z" />
                </svg>
              </a>
            </div>
          </div>

          <div className=" flex flex-col tracking-wider items-center p-4 rounded shadow-lg">
            <h2 className="mt-3 text-lg xl:text-xl">Ravish Kumar</h2>

            <h3 className="text-xs p-1 sm:text-sm 2xl:text-base text-gray-600 leading-6 text-center">
              Author | Mentor
            </h3>

            <Link
              to="/ravish-kumar/"
              className="px-3 mt-2 w-auto text-sm sm:text-base 2xl:text-lg tracking-wider shadow bg-new-green text-white hover:bg-light-green hover:text-gray-900 py-1 rounded transition duration-150"
            >
              Ravish Kumar Blogs
            </Link>

            <h3 className="text-xs mt-3 p-1 sm:text-sm 2xl:text-base text-gray-600 leading-6 text-center">
              ML | Data Science | Math
            </h3>
            <div className="flex">
              <a
                href="https://www.linkedin.com/in/ravishkumar2/"
                className="mt-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="sr-only">Linkedin</span>
                <svg
                  x="0px"
                  y="0px"
                  width="50"
                  height="50"
                  viewBox="0 0 48 48"
                  className="w-8 h-8"
                >
                  <path
                    fill="#0288D1"
                    d="M42,37c0,2.762-2.238,5-5,5H11c-2.761,0-5-2.238-5-5V11c0-2.762,2.239-5,5-5h26c2.762,0,5,2.238,5,5V37z"
                  ></path>
                  <path
                    fill="#FFF"
                    d="M12 19H17V36H12zM14.485 17h-.028C12.965 17 12 15.888 12 14.499 12 13.08 12.995 12 14.514 12c1.521 0 2.458 1.08 2.486 2.499C17 15.887 16.035 17 14.485 17zM36 36h-5v-9.099c0-2.198-1.225-3.698-3.192-3.698-1.501 0-2.313 1.012-2.707 1.99C24.957 25.543 25 26.511 25 27v9h-5V19h5v2.616C25.721 20.5 26.85 19 29.738 19c3.578 0 6.261 2.25 6.261 7.274L36 36 36 36z"
                  ></path>
                </svg>
              </a>
              <a
                href="https://medium.com/@ravishraj/"
                className="mt-4 ml-3"
                target="_blank"
                rel="noopener noreferrer"
              >
                <span className="sr-only">Medium</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 48 48"
                  width="26px"
                  height="26px"
                >
                  <path d="M 3 6 L 7 11.091797 L 7 35.285156 L 1 43 L 15 43 L 9 35.285156 L 9 13.75 L 22 43 L 21.998047 43.013672 L 34 13.544922 L 34 39 L 30 43 L 47 43 L 43 39 L 42.972656 10.503906 L 46.863281 6.0136719 L 34.845703 6.0136719 L 25.605469 28.744141 L 15.496094 6 L 3 6 z" />
                </svg>
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
