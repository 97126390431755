import React from "react"
import aboutImg from "../img/about-us.svg"
import impactImg from "../img/impact.svg"
import innovationImg from "../img/innovation.svg"
import { Helmet } from "react-helmet"
import successImg from "../img/success.svg"
import communityImg from "../img/community.svg"
import NavigationBar from "../components/Navbar"
import SocialMedia from "../components/social-media"
import Team from "../components/Team"
import loadable from "@loadable/component"

const Footer = loadable(() => import("../components/footer3"))

export default function AboutUs() {
  return (
    <div>
      <div className="mb-6">
        <Helmet
          htmlAttributes={{
            lang: "en",
          }}
        >
          <meta charSet="utf-8" />
          <title>About Us</title>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />
          <meta
            name="description"
            content="EnjoyAlgorithms is a computer science learning platform for students and professionals."
          />
        </Helmet>
        <div className="sm:sticky top-0 z-50">
          <NavigationBar />
        </div>
        <div className="bg-light-white">
          <div className="container mx-auto px-5 md:px-7 lg:px-8 xl:px-10 pt-5 md:pt-8 lg:pt-10">
            <div className="md:grid md:grid-cols-12 md:gap-8">
              <div className="mx-auto md:col-span-7 md:text-left">
                <h1 className="tracking-wider text-xl lg:text-2xl 2xl:text-3xl text-gray-900 text-center md:text-left">
                  A Team of Passionate Professionals
                </h1>

                <p className="text-left tracking-wider text-base xl:text-lg text-gray-600 text-left md:text-left mt-4">
                  We are a team of professionals from Tech industry, Super 30,
                  IIT, and Top tier universities who enjoy computer science,
                  programming and problem-solving. We are on a mission to
                  promote 21st-century skills in technology.
                </p>
              </div>
              <div className="md:-mt-2 lg:-mt-4 xl:-mt-6 md:col-span-5 flex justify-center">
                <img
                  className="object-cover pointer-events-none w-72 h-64 sm:w-96 sm:h-80 md:w-64 md:h-56 2xl:w-72 2xl:h-64"
                  src={aboutImg}
                  alt="About us page"
                />
              </div>
            </div>

            <ul className="grid grid-cols-1 lg:grid-cols-2 gap-12">
              <li className="sm:flex ">
                <div className="grid gap-4 sm:flex justify-items-center">
                  <img
                    className="pointer-events-none w-28 h-28 lg:w-36 lg:h-36"
                    src={impactImg}
                    alt=""
                  />
                  <div className="grid ml-6 gap-4 grid-cols-1">
                    <p className=" text-gray-900 tracking-wider  text-lg xl:text-xl text-center sm:text-left">
                      Impact Focused Approach
                    </p>

                    <p className=" flex text-gray-600 tracking-wider text-base xl:text-lg text-left sm:text-left">
                      Computer science provides solution to several real-life
                      problems. We see this as an opportunity to impact career
                      growth of millions of students and professionals.
                    </p>
                  </div>
                </div>
              </li>

              <li className="sm:flex ">
                <div className="grid gap-4 sm:flex justify-items-center ">
                  <img
                    className="pointer-events-none w-28 h-28 lg:w-36 lg:h-36"
                    src={innovationImg}
                    alt=""
                  />
                  <div className="grid ml-6 gap-4 grid-cols-1">
                    <p className=" text-gray-900 tracking-wider  text-lg xl:text-xl text-center sm:text-left">
                      Innovation In Education
                    </p>

                    <p className=" flex text-gray-600 tracking-wider text-base xl:text-lg text-left sm:text-left">
                      We are on a mission to organize and simplify computer
                      science knowledge. For this, we believe in continuous
                      innovation and learner-centric education.
                    </p>
                  </div>
                </div>
              </li>

              <li className="sm:flex ">
                <div className="grid gap-4 sm:flex justify-items-center ">
                  <img
                    className="pointer-events-none w-28 h-28 lg:w-36 lg:h-36 "
                    src={successImg}
                    alt=""
                  />
                  <div className="grid ml-6 gap-4 grid-cols-1">
                    <p className=" text-gray-900 tracking-wider  text-lg xl:text-xl text-center sm:text-left">
                      Success Oriented Guidance
                    </p>

                    <p className=" flex text-gray-600 tracking-wider text-base xl:text-lg text-left sm:text-left">
                      We help learners design a guided learning path to achieve
                      a dream career. For this, we follow design thinking
                      approach to create content with in-depth explanations.
                    </p>
                  </div>
                </div>
              </li>

              <li className="sm:flex ">
                <div className="grid gap-4 sm:flex justify-items-center ">
                  <img
                    className="pointer-events-none w-28 h-28 lg:w-36 lg:h-36"
                    src={communityImg}
                    alt=""
                  />
                  <div className="grid ml-6 gap-4 grid-cols-1">
                    <p className=" text-gray-900 tracking-wider  text-lg xl:text-xl text-center sm:text-left">
                      World Class Community
                    </p>

                    <p className=" flex text-gray-600 tracking-wider text-base xl:text-lg text-left sm:text-left">
                      We are on a mission to create a network of programmers and
                      problem solvers who love knowledge sharing, collaboration
                      and help each other achieve a dream career.
                    </p>
                  </div>
                </div>
              </li>
            </ul>

            <Team />
            <div className="mt-8">
              <SocialMedia />
            </div>
          </div>

          <div className="mt-8">
            <Footer />
          </div>
        </div>
      </div>
    </div>
  )
}
